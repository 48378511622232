<header *ngIf="auth.isLoggedIn">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light bg-transparent px-0 py-1">
      <a class="navbar-brand pointer" (click)="onClickHome()" id="menu_logo">
        <img alt="Kalpav Logo" src="../../../assets/images/kaplav-logo.png" class="logoSize pointer" />
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div *ngIf="dataService.getType === 'SUPER_ADMIN'" class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav m-auto font-14 regular">
          <li class="nav-item ext-dark pointer" routerLink="/admin" [ngClass]="{
              active:
                pageUrl === '/admin' ||
                pageUrl === '/admin/add-product' ||
                pageUrl === '/admin/vendor-product' ||
                pageUrl === '/admin/product-approval'
                  ? 'true'
                  : ''
            }">
            <a class="nav-link"> MANAGE PRODUCTS </a>
          </li>
          <!-- User Management Dropdown -->
          <li class="nav-item dropdown ext-dark pointer" [ngClass]="{ dropDownMenuManageUser: isDropdownMenuOpen }">
            <!-- <a class="nav-link dropdown-toggle">USER MANAGEMENT</a>
            <ul class="dropdown-menu text-uppercase" *dropdownMenu id="dropdown-basic">
              <li
                class="dropdown-item"
                routerLink="/admin/manage-vendor"
                [ngClass]="{ active: pageUrl === '/admin/manage-vendor' }"
              >
                Manage Vendors
              </li>
              <li
                class="dropdown-item"
                routerLink="/admin/user-list"
                [ngClass]="{ active: pageUrl === '/admin/user-list' }"
              >
                Manage Buyers
              </li>
              <li
                class="dropdown-item"
                routerLink="/admin/manage-admin"
                [ngClass]="{ active: pageUrl === '/admin/manage-admin' }"
              >
                Manage Admin
              </li>

            </ul> -->
            <div class="btn-group" dropdown>
              <a dropdownToggle type="button" class="nav-link dropdown-toggle" aria-controls="dropdown-basic">
                USER MANAGEMENT
              </a>
              <ul class="dropdown-menu font-14 text-uppercase" *dropdownMenu id="dropdown-basic">
                <li class="dropdown-item pointer" routerLink="/admin/manage-vendor"
                  [ngClass]="{ active: pageUrl === '/admin/manage-vendor' }">
                  Manage Vendors
                </li>
                <li class="dropdown-item pointer" routerLink="/admin/user-list"
                  [ngClass]="{ active: pageUrl === '/admin/user-list' }">
                  Manage Buyers
                </li>
                <li class="dropdown-item pointer" routerLink="/admin/manage-admin"
                  [ngClass]="{ active: pageUrl === '/admin/manage-admin' }">
                  Manage Admin
                </li>

              </ul>
            </div>
          </li>
          <li class="nav-item ext-dark pointer" routerLink="/admin/manage-cart"
            [ngClass]="{ active: pageUrl === '/admin/manage-cart' }">
            <a class="nav-link">CART</a>
          </li>

          <!-- <li
            class="nav-item ext-dark pointer"
            routerLink="/admin/manage-vendor"
            [ngClass]="{
              active:
                pageUrl === '/admin/manage-vendor' ||
                pageUrl === '/admin/add-vendor' ||
                pageUrl === '/admin/edit-vendor' ||
                (pageUrl === '/admin/order-details' && manageVendorState)
                  ? 'true'
                  : ''
            }"
          >
            <a class="nav-link"> MANAGE VENDORS </a>
          </li> -->
          <!-- <li
            class="nav-item ext-dark pointer"
            routerLink="/admin/user-list"
            [ngClass]="{
              active:
                pageUrl === '/admin/user-list' ||
                pageUrl === '/admin/buyer-order' ||
                (pageUrl === '/admin/order-details' && manageBuyersState)
                  ? 'true'
                  : ''
            }"
          >
            <a class="nav-link"> MANAGE BUYERS </a>
          </li> -->
          <li class="nav-item ext-dark pointer" routerLink="/admin/manage-order" [ngClass]="{
              active:
                pageUrl === '/admin/manage-order' ||
                (pageUrl === '/admin/order-details' && manageOrderState)
                  ? 'true'
                  : ''
            }">
            <a class="nav-link"> MANAGE ORDERS </a>
          </li>
          <!-- <li
            class="nav-item ext-dark pointer"
            routerLink="/admin/manage-admin"
            [ngClass]="{
              active:
                pageUrl === '/admin/manage-admin' ||
                pageUrl === '/admin/add-admin'
                  ? 'true'
                  : ''
            }"
          >
            <a class="nav-link"> MANAGE ADMIN </a>
          </li> -->
          <li class="nav-item ext-dark pointer" routerLink="/ask-the-expert" [ngClass]="{
              active:
                pageUrl === '/ask-the-expert' ||
                pageUrl === '/ask-the-expert-detail'
                  ? 'true'
                  : ''
            }">
            <a class="nav-link"> ASK THE EXPERT </a>
          </li>
          <div class="btn-group" dropdown>
            <a dropdownToggle type="button" class="nav-link dropdown-toggle pointer" aria-controls="dropdown-basic">
              BLOG
            </a>
            <ul class="dropdown-menu font-14 text-uppercase" *dropdownMenu id="dropdown-basic">
              <li class="dropdown-item pointer" routerLink="/manage-blog" [ngClass]="{
                active:
                pageUrl === '/manage-blog' ||
                pageUrl === '/manage-blog/add-blog' ||
                pageUrl === '/manage-blog/edit-blog'
                ? 'true'
                : ''
                }">
                Manage Blog
              </li>
              <li class="dropdown-item pointer" routerLink="/manage-blog/category"
                [ngClass]="{ active: pageUrl === '/manage-blog/category' }">
                Manage Blog-Category
              </li>

            </ul>
          </div>
        </ul>
      </div>
      <div *ngIf="
          dataService.getType === 'VENDOR' ||
          dataService.getType === 'WHOLESALER'
        " class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav m-auto font-14 regular">
          <li class="nav-item ext-dark pointer" routerLink="/vendor/all-products" [ngClass]="{
              active: pageUrl === '/vendor/all-products' ? 'true' : ''
            }">
            <a class="nav-link"> PRODUCT CATALOG </a>
          </li>
          <li class="nav-item ext-dark pointer" routerLink="/vendor/my-products" [ngClass]="{
              active:
                pageUrl === '/vendor/my-products' ||
                pageUrl === '/vendor/my-products/bulk-edit' ||
                pageUrl === '/vendor/product-detail'
                  ? 'true'
                  : ''
            }">
            <a class="nav-link"> MY PRODUCTS </a>
          </li>
          <li class="nav-item ext-dark pointer" routerLink="/vendor/manage-order" [queryParams]="{ state: 'allOrder' }"
            [ngClass]="{
              active:
                pageUrl === '/vendor/manage-order' ||
                pageUrl === '/vendor/order-details' ||
                pageUrl === '/vendor/bulk-order-details'
                  ? 'true'
                  : ''
            }">
            <a class="nav-link"> MANAGE ORDERS </a>
          </li>
          <li class="nav-item ext-dark pointer" routerLink="/vendor/profile" [ngClass]="{
              active: pageUrl === '/vendor/profile' ? 'true' : ''
            }">
            <a class="nav-link"> MY PROFILE </a>
          </li>
        </ul>
      </div>
      <div *ngIf="dataService.getType === 'RETAILER'" class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav m-auto font-14 regular">
          <li class="nav-item ext-dark pointer" routerLink="/retail/all-products" [ngClass]="{
              active: pageUrl === '/retail/all-products' ? 'true' : ''
            }">
            <a class="nav-link"> PRODUCT CATALOG </a>
          </li>
          <li class="nav-item ext-dark pointer" routerLink="/retail/my-products" [ngClass]="{
              active:
                pageUrl === '/retail/my-products' ||
                pageUrl === '/retail/my-products/bulk-edit' ||
                pageUrl === '/retail/product-detail'
                  ? 'true'
                  : ''
            }">
            <a class="nav-link"> MY PRODUCTS </a>
          </li>
          <li class="nav-item ext-dark pointer" routerLink="/retail/manage-order" [queryParams]="{ state: 'allOrder' }"
            [ngClass]="{
              active:
                pageUrl === '/retail/manage-order' ||
                pageUrl === '/retail/order-details' ||
                pageUrl === '/retail/bulk-order-details'
                  ? 'true'
                  : ''
            }">
            <a class="nav-link"> MANAGE ORDERS </a>
          </li>
          <li class="nav-item ext-dark pointer" routerLink="/retail/create-order" [ngClass]="{
                                            active: pageUrl === '/retail/create-order' ? 'true': ''}">
            <a class="nav-link"> CREATE ORDERS </a>
          </li>
          <li class="nav-item ext-dark pointer" routerLink="/retail/profile" [ngClass]="{
              active: pageUrl === '/retail/profile' ? 'true' : ''
            }">
            <a class="nav-link"> MY PROFILE </a>
          </li>


        </ul>
      </div>
      <div *ngIf="dataService.getType === 'KALPAV_WHOLESALE'" class="collapse navbar-collapse"
        id="navbarSupportedContent">
        <ul class="navbar-nav m-auto font-14 regular">
          <li class="nav-item ext-dark pointer" routerLink="/kalpav-wholesale/all-products" [ngClass]="{
              active: pageUrl === '/kalpav-wholesale/all-products' ? 'true' : ''
            }">
            <a class="nav-link"> PRODUCT CATALOG </a>
          </li>
          <li class="nav-item ext-dark pointer" routerLink="/kalpav-wholesale/my-products" [ngClass]="{
              active:
                pageUrl === '/kalpav-wholesale-wholesale/my-products' ||
                pageUrl === '/kalpav-wholesale/my-products/bulk-edit' ||
                pageUrl === '/kalpav-wholesale/product-detail'
                  ? 'true'
                  : ''
            }">
            <a class="nav-link"> MY PRODUCTS </a>
          </li>
          <li class="nav-item ext-dark pointer" routerLink="/kalpav-wholesale/manage-order"
            [queryParams]="{ state: 'allOrder' }" [ngClass]="{
              active:
                pageUrl === '/kalpav-wholesale/manage-order' ||
                pageUrl === '/kalpav-wholesale/order-details' ||
                pageUrl === '/kalpav-wholesale/bulk-order-details'
                  ? 'true'
                  : ''
            }">
            <a class="nav-link"> MANAGE ORDERS </a>
          </li>
          <!-- <li class="nav-item ext-dark pointer" routerLink="/kalpav-wholesale/create-order"
            [ngClass]="{active: pageUrl === '/kalpav-wholesale/create-order' ? 'true': ''}">
            <a class="nav-link"> CREATE ORDERS </a>
          </li> -->
          <li class="nav-item ext-dark pointer" routerLink="/kalpav-wholesale/profile" [ngClass]="{
              active: pageUrl === '/kalpav-wholesale/profile' ? 'true' : ''
            }">
            <a class="nav-link"> MY PROFILE </a>
          </li>


        </ul>
      </div>
      <div *ngIf="dataService.getType !== 'SUPER_ADMIN'" class="col pointer text-right">
        <a id="notificationLink" class="position-relative"><img alt="logout"
            src="../../../assets/images/notification.png" class="width-25" id="notification_li" />
          <div *ngIf="notificationCount > 0" id="notification_li"
            class="position-absolute rounded-circle notification-count text-white" style="font-size: x-small">
            {{ notificationCount }}
          </div>
        </a>
        <div id="notificationContainer" class="text-left animated fadeIn"
          [ngClass]="status ? 'displayBlock' : 'displayNone'">
          <div id="notificationTitle" class="latoB navbarGreenFont">
            Notifications
          </div>
          <div id="notificationsBody" class="notifications disputeListBoxScroll">
            <div class="px-2">
              <div *ngFor="let notification of allNotificationData"
                [ngClass]="notification.read ? '' : 'animated headShake'" class="borderBottomGrey pt-1">
                <div [ngClass]="
                    notification.read ? 'darkGreyFont' : 'navbarGreenFont'
                  " class="font-14 light pt-1">
                  {{ notification.title }}
                </div>

                <div [ngClass]="
                    notification.read ? 'text-dark' : 'navbarGreenFont'
                  " class="font-11 regular pb-1">
                  {{ notification.message }}
                </div>

                <div *ngIf="notification.message.length > 126" [ngClass]="
                    notification.read ? 'darkGreyFont' : 'navbarGreenFont'
                  " class="font-12 latoB pb-1 multi-line-text-ellipsis">
                  {{ notification.message }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a class="navbar-brand medium font-16 greyFont">
        Welcome
        <span *ngIf="dataService.getUserName">{{
          dataService.getUserName | customTitleCase
          }}</span>
        <span *ngIf="!dataService.getUserName">Admin</span>
        <img alt="Logout" src="../../../assets/images/logout.svg" (click)="onClickLogout()" title="Logout" id="logout"
          class="logoutIcon ml-3 pointer" />
      </a>
    </nav>
  </div>
</header>

<div style="height: 85px"></div>
