import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService, UserService, DataService } from 'src/app/services';
import { Router, ActivatedRoute } from '@angular/router';
import { Match } from 'src/app/validators/match.validator';
import { validateAllFormFields } from 'src/app/utils/validateform';
import { sha256 } from 'js-sha256';
@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  setPasswordForm: FormGroup;
  email: any;
  token: string;
  vendorId: string;
  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private router: Router,
              public route: ActivatedRoute,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.initSetPasswordForm();
    this.vendorId = this.route.snapshot.queryParamMap.get('id');
    this.email = this.route.snapshot.queryParamMap.get('email');
    this.token = this.route.snapshot.queryParamMap.get('token');
  }
  initSetPasswordForm() {
    this.setPasswordForm = this.formBuilder.group({
      password: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(5)
      ])],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(5)
      ])]
    },
      {
        validator: Match('password', 'confirmPassword')
      },
    );
  }
  onSubmit() {
    if (this.setPasswordForm.valid) {
      if (this.vendorId) {
        this.userService.changeVendorPasswordForAdmin(sha256(this.setPasswordForm.value.password),
        sha256(this.setPasswordForm.value.confirmPassword), this.vendorId).subscribe(res => {
          this.router.navigate(['/login']);
        });
      } else {
        this.userService.onSetPassword(sha256(this.setPasswordForm.value.password),
        sha256(this.setPasswordForm.value.confirmPassword), this.token).subscribe((res: any) => {
          this.router.navigate(['/login']);
        });
      }
    } else {
      validateAllFormFields(this.setPasswordForm);
    }

  }
}
