import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { IExpertsDetails } from '../interface/expert.interface';
import { RestResponse } from '../interface/RestResponse.interface';

@Component({
  selector: 'app-ask-the-expert-details',
  templateUrl: './ask-the-expert-details.component.html',
  styleUrls: ['./ask-the-expert-details.component.scss']
})
export class AskTheExpertDetailsComponent implements OnInit {
  expertId: string = ''
  expertDetails : IExpertsDetails = {} as IExpertsDetails
  constructor(private router: Router,
    private route:ActivatedRoute,
    private adminService:AdminService) {this.expertId = this.route.snapshot.queryParams.id }

  ngOnInit(): void {
    this.getExpertDetailById()
  }

  getExpertDetailById():void{
    if(this.expertId){
      this.adminService.getExpertsDataById(this.expertId).subscribe((res:RestResponse<IExpertsDetails>)=>{
        this.expertDetails = res.response
      })
    }
  }

  onNavigate() :void{
    this.router.navigate(['/ask-the-expert']);
  }

}
