import { NgModule } from '@angular/core';
import { IndianCurrency } from './currency.pipe';
import { CustomTitleCasePipe } from './custom-title-case.pipe';
import { DateAndTimePipe } from './date-and-time.pipe';
import { ISTDatePipe } from './IST-date.pipe';
import { BaseUnitPipe } from './base-unit.pipe';

@NgModule({
  declarations: [
    IndianCurrency,
    DateAndTimePipe,
    ISTDatePipe,
    CustomTitleCasePipe,
    BaseUnitPipe,
  ],
  exports: [
    IndianCurrency,
    DateAndTimePipe,
    ISTDatePipe,
    CustomTitleCasePipe,
    BaseUnitPipe,
  ],
})
export class PipesModule {}