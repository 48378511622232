import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from '../core/message/message.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private messagingService: MessageService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
               this.router.navigate(['/login']);
               sessionStorage.clear();
               const error = err.error.status.message || err.statusText;
               this.messagingService.notify('error', `Error: ${err.status}`, `${error}`);
            }
            return throwError(err);
        }));
    }
}
