import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nocontent',
  templateUrl: './nocontent.component.html',
  styleUrls: ['./nocontent.component.scss']
})
export class NoContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
