export enum EBaseUnit {
  KILO_GRAM = "Kgs",
  QUINTAL = "Quintal",
  TONNE = "Ton",
  POUND = "Lbs",
  BOX = 'Box',
  GRAM = 'gms',
  LITRE = 'liter',
  MILI_LITRE  = "ml",
  PIECES = 'Piece'
}
