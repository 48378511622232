import { Pipe, PipeTransform, Output } from '@angular/core';

@Pipe({name: 'indianCurrency'})
export class IndianCurrency implements PipeTransform {
  transform(value: string ): string {
    if (value !== '' && value !== null) {
    value = value.toString();
    let amount;
    // would be true. Period found in file name
    if (value.indexOf('.') !== -1) {
      const  actualInput = value.split('.');
      amount = actualInput[0].replace(/\,/g, '');
      const output = parseInt(amount, 10).toLocaleString('en-IN');
      return output + '.' + actualInput[1];
    } else {
      amount = value.replace(/\,/g, '');
      const output = parseInt(amount, 10).toLocaleString('en-IN');
      return output;
    }
  }
    return value;
}
  }
