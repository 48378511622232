import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from './message.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
type Severities = 'success' | 'warning' | 'error';
interface Notification {
  severity: Severities;
  summary: string;
  detail: string;
  timeOut: number;

}
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(private toastrService: ToastrService, private messageService: MessageService) {
    this.subscribeToNotifications();

   }

  ngOnInit(): void {
  }
  subscribeToNotifications() {
    this.subscription = this.messageService.notificationChange
    .subscribe((notification: Notification) => {
      this.toastrService[notification.severity](notification.summary, notification.detail , {
      timeOut: notification.timeOut
    });

    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
