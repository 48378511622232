<form [formGroup]="searchForm">
    <div class="row pb-4">
        <div class="col-4">
            <input class="filter" type="text" noMultipleSpace id="keyword" formControlName="keyword" placeholder="Search by product name or  product code">
        </div>
        <div class="col-3">
            <select class="filter" id="category" formControlName="category" (change)="onChangeFilter()">
                <option value="" selected>Select Category</option>
                <option class="pointer" *ngFor="let category of allCategories; let i = index"
                    [value]="category.productCategoryId">
                    {{ category.productCategoryName }}
                </option>
            </select></div>
        <!-- <div class="col-2">
            <select class="filter" id="subCategory" formControlName="subCategory" (change)="onChangeFilter()">
                <option value="" selected>Select SubCategory</option>
                <option class="pointer" *ngFor="let category of allSubCategories; let i = index"
                    [value]="category.productSubCategoryId">
                    {{ category.productSubCategoryName }}
                </option>
            </select></div> -->
        <div class="col-3" *ngIf="showStatusFilter">
            <select class="filter" id="status" formControlName="status" (change)="onChangeFilter()">
                <option value="" selected>Select Status</option>
                <option class="pointer" *ngFor="let status of allStatus; let i = index" [value]="status.key">
                    {{ status.value }}
                </option>
            </select></div>
        <div class="col-2 text-right">
            <button (click)="onClear()" class="w-100 font-14 light height-36 orangeBG marginTopBtm-5 border-0 text-white" id="clearFilter">
                Clear
            </button>
        </div>
    </div>
</form>