import { NgModule } from '@angular/core';
import { DragDropDirective } from '../directives/dragdrop.directive';
import { TextUpperCaseDirective } from '../directives/textcapitalize.directive';
import { ModalModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FiltersComponent } from './filters/filters.component';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { NoMultipleSpaceDirective } from '../directives/nomultiplespace.directive';
@NgModule({
  declarations: [TextUpperCaseDirective, NoMultipleSpaceDirective,DragDropDirective,FiltersComponent] ,
  imports: [
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  exports: [
    DragDropDirective,
    TextUpperCaseDirective,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    FiltersComponent,
    NoMultipleSpaceDirective
  ],
  providers: [DatePipe],
})
export class SharedModule  { }


