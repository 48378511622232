import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from './services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'kalpav-ui';
  constructor(private location: Location,
              private auth: AuthService
              ) {
    if (this.location.path().startsWith('/set-password')) {
      return;
    }
}
}
