import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { IExpertsDetails } from '../interface/expert.interface';
import { Router } from '@angular/router';
import { RestResponse } from '../interface/RestResponse.interface';
import { PaginationResponse } from '../interface/pagination-response.interface';
@Component({
  selector: 'app-ask-the-expert',
  templateUrl: './ask-the-expert.component.html',
  styleUrls: ['./ask-the-expert.component.scss']
})
export class AskTheExpertComponent implements OnInit {
  currentPage = 1;
  pageSize = 50;
  totalElements = 0;
  allExpertDetails:IExpertsDetails[] =[]
  constructor(private adminService: AdminService,
    private router: Router) { }

  ngOnInit(): void {
    this.getExpertsData()
  }

  getExpertsData() :void{
     this.adminService.getAllExpertsData(
      this.currentPage , 
     ).subscribe((res:RestResponse<PaginationResponse<IExpertsDetails[]>>)=>{
      this.totalElements = res.response.totalElements;
    this.allExpertDetails = res.response.content
     })
  }

  get initIndex() :number {
    let index = 0;
    index = Number((this.pageSize * (this.currentPage - 1)));
    return index;
  }

  onDetails(id:string) :void{
    this.router.navigate(['/ask-the-expert-detail'], { queryParams: { id } });
  }
  pageChanged(event) {
    this.currentPage = event.page;
    this.getExpertsData();
  }
}
