import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RestResponse } from 'src/app/interface/RestResponse.interface';
import { INotification } from 'src/app/interface/notification.interface';
import { PaginationResponse } from 'src/app/interface/pagination-response.interface';
import { DataService } from 'src/app/services';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: []
})
export class MenuComponent implements OnInit {

  classApplied = true;
  welcomeMessage: string;
  pageUrl: any;
  modalRef: BsModalRef;
  manageVendorState: boolean;
  manageOrderState: boolean;
  manageBuyersState: boolean;
  config = {
    animated: true,
    ignoreBackdropClick: false,
    class: 'forgotPasswordModal'
  };
  notificationCount = 0;
  allNotificationData: INotification[] = [];
  status = false;
  pageNumber = 0;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  public isDropdownMenuOpen: boolean = false;

  public toggleDropdownMenu(): void {
    this.isDropdownMenuOpen = !this.isDropdownMenuOpen;
  }

  @HostListener('document:click', ['$event'])
  onMouseMove(e) {
    const id = e.target.attributes.id;
    if (id !== undefined) {
      if (
        e.target.attributes.id.nodeValue !== undefined &&
        e.target.attributes.id.nodeValue === 'notification_li'
      ) {
        this.clickNotification();
      } else {
        if (this.status) {
          this.status = false;
          this.setNotificationsAsRead();
        }
      }
    } else {
      if (this.status) {
        this.status = false;
        this.setNotificationsAsRead();
      }
    }
  }
  constructor(private router: Router, public auth: AuthService, public dataService: DataService,
              private modalService: BsModalService, private notificationService: NotificationService) {
    this.dataService.getFromLocalStorage();
  }
  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.pageUrl = event.url;
        this.pageUrl = this.pageUrl.split('?', 1)[0];
        this.manageVendorState = event.url.includes('vendor') ? true : false;
        this.manageOrderState = event.url.includes('orders') ? true : false;
        this.manageBuyersState = event.url.includes('buyer') ? true : false;
        if (this.dataService.getUserId) {
          this.getAllNotification();
        }
      }
    });

  }

  onClickHome() {
    if (this.dataService.getType === 'SUPER_ADMIN') {
      this.router.navigate(['/admin']);
    } else if (this.dataService.getType === 'VENDOR') {
      this.router.navigate(['/vendor/all-products']);
    }
  }

  manageProducts() {
    this.router.navigate(['/admin']);
  }
  addProducts() {
    this.router.navigate(['/admin/add-product']);
  }

  adminHome(template) {
    this.modalRef = this.modalService.show(template, this.config);
    // this.router.navigate(['/admin']);
  }

  manageVendors() {
    this.router.navigate(['/admin/manage-vendor']);
  }

  allProducts() {
    this.router.navigate(['/vendor/all-products']);
  }

  myProducts() {
    this.router.navigate(['/vendor/my-products']);
  }

  onClickLogout() {
    this.auth.logout().subscribe((res) => {});
    this.auth.forceLogout();
  }

  clickNotification() {
    if (!this.status) {
      this.status = !this.status;
    }
  }
  setNotificationsAsRead() {
    let notificationIdData = [];
    this.allNotificationData.length > 0 ? this.allNotificationData.forEach((res) => {
      notificationIdData.push(res.notificationId);
    }) : notificationIdData = [];
    if (notificationIdData.length > 0) {
      this.notificationService.updateNotificationsAsRead(notificationIdData).subscribe((ele) => {
        this.allNotificationData = [];
        this.pageNumber = 1;
        this.getAllNotification();
      });
    }
  }
  getAllNotification() {
    const request = {
      pageNo : 1,
      pageSize : 20,
      dateFrom : '',
      dateTo : '',
      dateFilterOn : 'createdOn',
      sortBy : 'createdOn',
      descending: 'true'
    };
    this.notificationCount = 0;
    this.notificationService
      .getAllNotification(request)
      .subscribe((el: RestResponse<PaginationResponse<Array<INotification>>>) => {
        this.allNotificationData = el.response.content;
        this.allNotificationData.forEach((res) => {
          if (res.status === 'NEW') {
            this.notificationCount = this.notificationCount + 1;
          }
        });
      });
  }
}
