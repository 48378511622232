import { IDropdown, IEnumToArray } from '../interface/master.interface';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { EBaseUnit } from '../enums/base-unit.enum';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  // function to convert enum into array
  public getEnumArray(enumObj: Record<string, string>): IEnumToArray[] {
    const enumArray = [];
    for (const [enumKey, enumValue] of Object.entries(enumObj)) {
      if (!Number.isNaN(Number(enumKey))) {
        continue;
      }
      enumArray.push({ id: enumKey, value: enumValue });
    }
    return enumArray;
  }

  getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : '';
  }

  calculateWeight(unit: string, packetSize: number, quantity: number): number {
    let weight = 0;
    if (unit && packetSize && quantity) {
      switch (unit) {
        case this.getEnumKeyByEnumValue(EBaseUnit, EBaseUnit.KILO_GRAM):
          weight = packetSize * quantity;
          break;
        case this.getEnumKeyByEnumValue(EBaseUnit, EBaseUnit.GRAM):
          weight = (packetSize / 1000) * quantity;
          break;
        case this.getEnumKeyByEnumValue(EBaseUnit, EBaseUnit.TONNE):
          weight = packetSize * 1000 * quantity;
          break;
        case this.getEnumKeyByEnumValue(EBaseUnit, EBaseUnit.LITRE):
          weight = packetSize * quantity;
          break;
        case this.getEnumKeyByEnumValue(EBaseUnit, EBaseUnit.MILI_LITRE):
          weight = (packetSize / 1000) * quantity;
          break;
        case this.getEnumKeyByEnumValue(EBaseUnit, EBaseUnit.PIECES):
          weight = packetSize * quantity;
          break;
        default:
          break;
      }
    }
    return +weight.toFixed(2);
  }

  // Method to convert youtube link to embedded youtube URL
  convertLinkToEmbeddedLink(url: string) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    const videoId = match && match[2].length === 11 ? match[2] : null;

    //constructing youtube embedded URL
    return `https://www.youtube.com/embed/${videoId}`;
  }

  getTimeDifference(createdOnStr: string): string {
    const today = new Date();
    if (createdOnStr) {
      const createdOn = new Date(createdOnStr);
      const time = today.getTime() - createdOn.getTime();
      const dayDifference = time / (1000 * 60 * 60 * 24);
      if (dayDifference < 1) {
        const diffInHours = (Math.abs(time) / (1000 * 60 * 60)) % 24;
        if (diffInHours < 1) {
          const diffInMin = (Math.abs(time) / (1000 * 60)) % 60;
          if (diffInMin < 1) {
            const diffInSec = (Math.abs(time) / 1000) % 60;
            return `${diffInSec.toFixed(0)} seconds ago`;
          }
          return `${diffInMin.toFixed(0)} minutes ago`;
        }
        return `${diffInHours.toFixed(0)} hours ago`;
      }
      return `${dayDifference.toFixed(0)} days ago`;
    }
    return '';
  }

  public paramChange(paramValue: any): void {
    const queryParams: Params = paramValue;
    if (Object.keys(queryParams).length !== 0) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([], { replaceUrl: true });
    }
  }

  /**
   * convert a Date object to s specified format
   * Note: If both params are empty it will return null
   * @param date Accepts a date object
   * @param format Accepts string, used for specifying the format to be converted
   */
  public convertDate(date: Date, format: string): string | null {
    return date && format ? new DatePipe('en').transform(date, format) : null;
  }

  public checkValidAndDirty(control: AbstractControl | null | undefined) {
    return control && control.invalid && control.dirty ? true : false;
  }

  public initIndex(pageNumber: number, pageSize: number) {
    return Number(pageSize * (pageNumber - 1));
  }

  public convertToDate(originalDate: string | Date): string {
    const convertedDateArray =
      typeof originalDate === 'string' ? originalDate.split('-') : [];
    return convertedDateArray.length > 0
      ? convertedDateArray[2] +
          '-' +
          convertedDateArray[1] +
          '-' +
          convertedDateArray[0]
      : '';
  }

  // function to check if the object value exists and return '' instead of the value. to reduce code
  public ifExists(obj: any) {
    return obj ? obj : '';
  }

  public scrollById(id: string): void {
    document
      .getElementById(id)
      ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  getQueryParam(paramName: string): string {
    return this.route.snapshot.queryParamMap.get(paramName) || '';
  }

  // Function to remove empty values inside an object
  public removeEmpty(object: any): any {
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        if (object[key] instanceof Object) {
          this.removeEmpty(object[key]);
        }
        if (
          object[key] === null ||
          object[key] === '' ||
          object[key] === undefined
        )
          delete object[key];
      }
    }
    return object;
  }

  public isFieldInvalid(control: string, formGroup: FormGroup) {
    return formGroup.get(control)?.invalid && formGroup.get(control)?.dirty;
  }

  public convertString(status: string): string {
    if (status) {
      return status.replace(/[&\/\\#, +()$~%.'":*?<>{}_]/g, ' ');
    } else {
      return ' ';
    }
  }

  public validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({
          onlySelf: true,
        });
        control.markAsDirty({
          onlySelf: true,
        });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach((ele) => {
          this.validateAllFormFields(ele as FormGroup);
        });
      }
    });
  }
  public scrollToFirstInvalidControl() {
    if (isPlatformBrowser(this.platformId)) {
      let firstInvalidControl: any;
      let all = document.getElementsByClassName('ng-invalid');
      for (var i = 0; i < all.length; i++) {
        if (all[i].tagName == 'INPUT') {
          firstInvalidControl = all[i];
          break;
        }
        if (all[i].tagName == 'CKEDITOR') {
          firstInvalidControl = all[i];
          break;
        }
        if (all[i].tagName == 'MAT-FORM-FIELD') {
          firstInvalidControl = all[i];
          break;
        }
        if (all[i].tagName == 'TEXTAREA') {
          firstInvalidControl = all[i];
          break;
        }
      }
      this.containerEl.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      });

      fromEvent(this.containerEl, 'scroll')
        .pipe(debounceTime(0), take(1))
        .subscribe(() => {
          firstInvalidControl.focus();
        });
    }
  }

  private getTopOffset(controlEl: HTMLElement): number {
    if (isPlatformBrowser(this.platformId)) {
      const labelOffset = 100;
      const controlElTop = controlEl.getBoundingClientRect().top;

      const absoluteControlElTop = controlElTop + window.scrollY;

      return absoluteControlElTop - labelOffset;
    } else {
      return 0;
    }
  }
  private get containerEl(): HTMLElement {
    if (isPlatformBrowser(this.platformId)) {
      return window as any;
    } else {
      return this.containerEl;
    }
  }

  public convertToDropdownOptions = (data: any[], idKey: string, valueKey: string): IDropdown[] => {
    let options: IDropdown[] = [];
    for (let i = 0; i < data.length; i++) {
      let option = { label: "", value: "" };
      option["label"] = data[i][valueKey];
      option["value"] = data[i][idKey];
      options.push(option);
    }
    return options;
  };
}
