import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
type Severities = 'success' | 'info' | 'warn' | 'error';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  notificationChange: Subject<object> = new Subject<object>();
  /**
   * Notification Message Service
   * @param severity - accepts severity types `success` `info` `warn` `error`.
   * @param summary - heading for the notification
   * @param detail - details of the notfication
   */
  notify(severity: Severities, summary: string, detail: string) {
    this.notificationChange.next({ severity, summary, detail, timeOut: 5000 });
  }
}
