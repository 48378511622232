import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageComponent } from './core/message/message.component';
import { LoaderComponent } from './core/loader/loader.component';
import { SharedModule } from './shared/shared.module';
import { SharedServiceModule } from './services/sharedservice.module';
import { MenuModule } from './menu/menu.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptor } from './interceptors/http.interceptor';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NoContentComponent } from './nocontent/nocontent.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { AskTheExpertComponent } from './ask-the-expert/ask-the-expert.component';
import { PipesModule } from './pipes/pipe.module';
// import { PopoverModule } from 'ngx-bootstrap/popover';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap';
import { CollapseModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PendingChangesGuard } from './guard/pendingChanges.guard';
import { AskTheExpertDetailsComponent } from './ask-the-expert-details/ask-the-expert-details.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LoaderComponent,
    MessageComponent,
    NoContentComponent,
    SetPasswordComponent,
    ErrorPageComponent,
    AskTheExpertComponent,
    AskTheExpertDetailsComponent,
  ],
  imports: [
    BrowserModule,
    // PopoverModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastClass: 'ngx-toastr customToast',
    }),
    SharedModule,
    SharedServiceModule.forRoot(),
    HttpClientModule,
    MenuModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    PipesModule,
  ],
  providers: [
    PendingChangesGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
