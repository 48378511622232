import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'istDate' })
export class ISTDatePipe implements PipeTransform {
  transform(date: Date | string): string {
    if(typeof(date) === 'string'){
      date = new Date(date);  // if orginal type was a string
    }
    return new DatePipe('en-US').transform(date, 'dd/MM/yyyy', 'UTC');
  }
}