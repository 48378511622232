import { RestService } from './../core/rest.service';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { RestResponse } from '../interface/RestResponse.interface';
import { PaginationResponse } from '../interface/pagination-response.interface';
import { INotification } from '../interface/notification.interface';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(public restService: RestService) { }

  getAllNotification(notificationFilterData): Observable<RestResponse<PaginationResponse<Array<INotification>>>> {
    return this.restService.get(Environment.UrlPath + `/notifications?type=WARNING&type=INFO&type=REMINDER&type=ALERT&status=READ&status=NEW&messageType=INAPP`,
    notificationFilterData, false).pipe(
      map((res: RestResponse<PaginationResponse<Array<INotification>>>) => res),
      catchError(error => throwError(error))
    );
  }
  updateNotificationsAsRead(data): Observable<any> {
    return this.restService.put(Environment.UrlPath + `/notifications`,
    data, false).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }

}
