<div class="container">
    <div class="row">
        <div class="col-8">
            <h1 class="bold font-24 py-4">Set Password</h1>
        </div>
        <div class="col-4">
            <div class="py-4 text-right">
                <!--  if any button --- add here -->
            </div>
        </div>
    </div>

    <div class="px-3">
        <form [formGroup]="setPasswordForm" (ngSubmit)="onSubmit()">
            <div class="row">

                <div class="col-6 tableShadow lightGreyBG">
                    <div class="p-5 my-5 setPasswordBox text-center">
                        <img alt="Reset Password" src="../../../assets/images/reset-password.svg"
                            class="setPasswordIcon mt-3" />
                        <div class="light font-16 greyFont pt-3">Reset your password here</div>
                    </div>
                </div>
                <div class="col-6 tableShadow bg-white">
                    <div class="p-5 my-5 setPasswordBox">
                        <div class="font-12 regular py-1">Password</div>
                        <div>
                            <input type="password" formControlName="password" maxlength="20"
                                class="productEditInput w-100 font-14 light px-2" placeholder="Enter your password">
                            <div
                                *ngIf="setPasswordForm.controls['password'].invalid && setPasswordForm.controls['password'].dirty">
                                <span class="error"
                                    *ngIf="setPasswordForm.controls['password'].errors.required">Password is
                                    required</span>
                                <span class="error"
                                    *ngIf="setPasswordForm.controls['password'].errors.minlength">Password
                                    needs to be atleast 5
                                    characters long</span>
                            </div>
                        </div>
                        <div class="font-12 regular pb-1 pt-4">Confirm Password</div>
                        <div>
                            <input type="password" formControlName="confirmPassword" maxlength="20"
                                class="productEditInput w-100 font-14 light px-2" placeholder="Confirm your password">
                            <div
                                *ngIf="setPasswordForm.controls['confirmPassword'].invalid && setPasswordForm.controls['confirmPassword'].dirty">
                                <span class="error"
                                    *ngIf="setPasswordForm.controls['confirmPassword'].errors.required">Confirm
                                    Password is required</span>
                                <span class="error"
                                    *ngIf="setPasswordForm.controls['confirmPassword'].errors.minlength">
                                    Confirm Password needs to be atleast 5 characters long</span>
                                <span class="error" *ngIf="setPasswordForm.controls['confirmPassword'].errors.match">
                                    Passwords didn't match. Try again</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row py-5">
                <div class="col-lg-10 col-md-6 col-sm-4"></div>
                <!-- <div class="col-lg-2 col-md-3 col-sm-6 col-12"><button
                        class="w-100 font-14 light height-36 cancelBtn">Cancel</button></div> -->
                <div class="col-lg-2 col-md-3 col-sm-6 col-12"><button type="submit"
                        class="w-100 orangeBG text-white font-14 light height-36 border-0 ">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>