<div class="loginBackground">
  <div class="container">
    <div class="m-5 p-5">
      <div class="row p-5">
        <div class="col-xs-12 col-lg-12 col-md-12 col-sm-12 col-12 tableShadow bg-white minHeight-60vh">


          <div class="row">
            <div class="col-xs-7 col-lg-7 col-md-7 col-sm-7 col-12">

              <div class="">
                <div class="text-center"></div>
                <div class="py-5 px-5">
                  <div class="pb-3 pt-5 text-center bold font-24 greyFont">
                    <span>Sign in to</span> <img alt="logo" src="../../assets/images/kaplav-logo.png"
                      class="logoSize ml-2" style="margin-top: -14px;">
                  </div>
                  <div class="py-4">
                    <form [formGroup]="loginForm" (ngSubmit)="onClickLogin()">
                      <div class="text-info small"><sup class="font-red"></sup>
                        <div class="row">
                          <div class="col-md-12 text-center">
                            <input class="loginInput px-2 font-14 light w-100 my-1" placeholder="Email" id='email'
                              formControlName="email" type="text" maxlength="50">
                            <div class="error loginError"
                              *ngIf="loginForm.controls['email'].invalid && loginForm.controls['email'].dirty">
                              <span *ngIf="loginForm.controls['email'].errors.required">Please enter your
                                email-id</span>
                              <span
                                *ngIf="loginForm.controls['email'].errors.email && !loginForm.controls['email'].errors.maxlength && !loginForm.controls['email'].errors.required">Please
                                enter your email address in format : Yourname@example.com</span>
                            </div>
                          </div>
                          <div class="col-md-12 my-2 text-center">
                            <input placeholder="Password" maxlength="20" type="password"
                              class="loginInput px-2 font-14 light w-100 my-1" id='password ' name="password"
                              formControlName="password">
                            <div class="error loginError"
                              *ngIf="loginForm.controls['password'].invalid &&loginForm.controls['password'].dirty">
                              <span *ngIf="loginForm.controls['password'].errors.required">Please enter the
                                password</span>
                              <span *ngIf="loginForm.controls['password'].errors.minlength">
                                Password needs to be atleast 5 characters long</span>
                            </div>
                          </div>
                          <div class="text-center w-100 pb-4 pt-3 pointer">
                            <a (click)="openModal(template)" class="font-12 light pointer text-dark">Forgot Password
                              ?</a>
                          </div>
                        </div>
                      </div>

                      <div class="row pb-4">
                        <div class="col-md-12 text-center">
                          <button class="border-0 text-white orangeBG pointer font-14 light py-1 px-5"
                            type="submit">Sign
                            in</button> </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-xs-5 col-lg-5 col-md-5 col-sm-5 col-12 orangeBG">

              <div class="text-center px-3 pad-top-btm-55">
                <h2 class="text-white Bold">Hello!</h2>
                <div class="font-14 text-white light">Login with your email and password</div>
              </div>

            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</div>



<!--Forgot Password Modal-->

<ng-template #template>
  <form [formGroup]="modelForm">
    <div class="modal-body">
      <button type="button" class="close pull-right text-white font-16" aria-label="Close" (click)="clearEmailField()">
        <span aria-hidden="true">&times;</span>
    </button>
      <div class="row">
        <div class="col-md-12 font-14 text-center">
          <input class="loginInput px-2 font-14 light w-100 my-1" type="text" placeholder="Enter your email ID"
            formControlName="emailAddress">

          <div *ngIf="
                modelForm.controls['emailAddress'].invalid &&
                modelForm.controls['emailAddress'].dirty
                ">
            <span class="error text-white" *ngIf="modelForm.controls['emailAddress'].errors.required">Email address is required</span>
            <span class="error text-white" *ngIf="
                  modelForm.controls['emailAddress'].errors.email &&
                  !modelForm.controls['emailAddress'].errors.required
                  ">Please enter your email address in format <br />
              for example: Yourname@example.com</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col-md-12 text-center">
        <button class="border-0 text-white bg-dark pointer font-14 light py-1 px-3 forgotPaswrdBtn" type="submit"
          (click)="onClickResetPassword()">Reset my password</button> </div>
    </div>
  </form>
</ng-template>