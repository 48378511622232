
import { Injectable, Compiler } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { RestService } from '../core/rest.service';
import { map, catchError } from 'rxjs/operators';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private restService: RestService,
              private compiler: Compiler,
              private dataService: DataService,
              private router: Router) { }

  public login(data): Observable<{}> {
    return this.restService
      .oauthLogin(Environment.baseUrl + `/oauth/token`, data, false).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public logout(): Observable<{}> {
    return this.restService
      .oauthLogout(Environment.baseUrl + `/oauth/logout`, null, true).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  getForgotPasswordEmailLink(data) {
    return this.restService
      .post(
        Environment.baseUrl + `/adresnow/user/forgotpassword`, data, false
      )
      .pipe(map((res: any) => res));
  }
  onSettingPassword(data, email): Observable<any> {
    return this.restService.
      put(Environment.UrlPath + '/auth/setPassword/' + email, data, null).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  onOtpVerification(data, email): Observable<any> {
    return this.restService.
      get(Environment.UrlPath + email, data, null).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public forgotPassword(registeredEmail): Observable<any> {
    return this.restService
      .put(Environment.UrlPath + '/user/forgotPassword' , {registeredEmail}, null).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public forceLogout() {
    this.compiler.clearCache();
    this.dataService.clearOut();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }
  get isLoggedIn() {
    if (sessionStorage.getItem('user_type') !== null) {
      return true;
    }
    return false;
  }
}
