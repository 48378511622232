import { EBaseUnit } from './../enums/base-unit.enum';
import { UtilityService } from 'src/app/services/utility.service';
import { Pipe, PipeTransform } from '@angular/core';
import { IEnumToArray } from '../interface/master.interface';

@Pipe({
  name: 'baseUnit',
})
export class BaseUnitPipe implements PipeTransform {
  constructor(private utilityService: UtilityService) {}
  transform(value: string, ...args: unknown[]): unknown {
    if (!value) {
      return '';
    }
    const baseUnitEnums: {
      id: string;
      value: string;
    }[] = this.utilityService.getEnumArray(EBaseUnit);
    let baseUnit: IEnumToArray = baseUnitEnums.filter(
      (ele) => ele.id === value
    )[0];
    return baseUnit && baseUnit.value ? baseUnit.value : '';
  }
}
