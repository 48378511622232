<div class="container">
    <div class="row">
        <div class="col-8">
            <h1 class="bold font-24 py-4">Ask the Expert Details</h1>
        </div>
        <div class="col-4">
            <div class="py-4 text-right">
                <button class="addBtn orangeBG text-white font-14 light py-1 px-3 border-0" (click)="onNavigate()">Ask
                    the expert</button>
            </div>

        </div>
    </div>
    <div class="px-5 py-4 mb-5 ask-the-expert-details">
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <label class="font-12 text-grey regular m-0 py-1">Name :</label>
                <div class="font-15 bold text-dark">{{expertDetails.senderName}}</div>
            </div>


            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <label class="font-11 text-grey regular m-0 py-1">Email :</label>
                <div class="font-14 bold text-dark">{{expertDetails.senderEmail}}</div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <label class="font-11 text-grey regular m-0 py-1">Phone Number :</label>
                <div class="font-14 bold text-dark">{{expertDetails.senderPhoneNumber}}</div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <label class="font-11 text-grey regular m-0 py-1">Appointment Date :</label>
                <div class="font-14 bold text-dark">{{expertDetails.bookingDate | dateAndTime}}</div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <label class="font-11 text-grey regular m-0 py-1">Category :</label>
                <div class="font-14 bold text-dark">{{expertDetails.category}}</div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <label class="font-11 text-grey regular m-0 py-1">Description :</label>
                <div class="font-14 bold text-dark">{{expertDetails.description}}</div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <label class="font-11 text-grey regular m-0 py-1">Created Date :</label>
                <div class="font-14 bold text-dark">{{expertDetails.createdDate | dateAndTime}}</div>
            </div>
        </div>

    </div>
</div>