import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { AllCategories, AllSubCategories } from 'src/app/interface/product-list.interface';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  @Output() filtersChange = new EventEmitter();
  @Input() showStatusFilter = true;
  searchForm: FormGroup;
  allCategories: AllCategories[];
  allSubCategories: AllSubCategories[] = [];
  allStatus: { key: string; value: string; }[];

  constructor(private formBuilder: FormBuilder, private adminService: AdminService) { }

  ngOnInit(): void {
    this.allStatus =  [
      { key: 'ACTIVE', value: 'APPROVED' },
      { key: 'NEW ', value: 'NEW' },
      { key: 'DEACTIVATED ', value: 'DEACTIVATED' },
      { key: 'REJECTED ', value: 'REJECTED' },
      { key: 'INACTIVE ', value: 'PENDING APPROVAL' },
    ];
    this.initSearchForm();
    this.getAllCategory();
  }

  initSearchForm() {
    this.searchForm = this.formBuilder.group({
      category: new FormControl('', []),
      subCategory: new FormControl('', []),
      status: new FormControl('', []),
      keyword: new FormControl('', []),
    });
    this.filteredSubCategories();
    this.initValueChanges(this.searchForm.value);
  }

  getAllCategory() {
    this.adminService.getAllCategories().subscribe(res => {
      this.allCategories = res.response;
    });
  }

  filteredSubCategories() {
    this.searchForm.controls.category.valueChanges.subscribe(res => {
      this.adminService.listProductSubCategories(res).subscribe(response => {
        this.allSubCategories = response.response;
      });
    });
  }

  initValueChanges(values) {
    this.searchForm.get('keyword').valueChanges.pipe(debounceTime(200)).subscribe((keyword) => {

      if (keyword.length > 2 || keyword.length === 0) {
        const filters = {
          category: values.category, subCategory: values.subCategory,
          status: values.status, keyword
        };
        this.filtersChange.next(filters);
      }
    });
  }

  onChangeFilter() {
    this.filtersChange.next(this.searchForm.value);
  }

  onClear() {
    this.initSearchForm();
    this.allSubCategories = [];
    this.filtersChange.next(this.searchForm.value);
  }

}
