import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { CustomValidators } from '../validators/custom.validator';
import { validateAllFormFields } from '../utils/validateform';
import { DataService } from '../services/data.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { sha256 } from 'js-sha256';
import { AdminService } from '../services/admin.service';
import { EUserType } from '../enums/login.enum';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthService],
})
export class LoginComponent implements OnInit {
  modalRef: BsModalRef;
  public loginForm: FormGroup;
  public modelForm: FormGroup;
  EUserType: EUserType;

  config = {
    animated: true,
    ignoreBackdropClick: false,
    class: 'forgotPasswordModal'
  };

  constructor(
    private authService: AuthService,
    private formbuilder: FormBuilder,
    private router: Router,
    private modalService: BsModalService,
    private dataService: DataService,
    public auth: AuthService,
    private adminService: AdminService
  ) {
    if (this.dataService.userData === EUserType.SUPER_ADMIN) {
      this.router.navigate(['/admin/manage-order']);
    } else if (this.dataService.userData === EUserType.VENDOR) {
      this.router.navigate(['/vendor/all-products']);
    } else if (this.dataService.userData === EUserType.WHOLESALER) {
      this.router.navigate(['/vendor/all-products']);
    } else if (this.dataService.userData === EUserType.RETAILER) {
      this.router.navigate(['/retail/all-products']);
    } else if (this.dataService.userData === EUserType.KALPAV_WHOLESALE) {
      this.router.navigate(['/kalpav-wholesale/all-products']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formbuilder.group({
      email: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        CustomValidators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20),
      ]),
    });
    this.modelForm = this.formbuilder.group({
      emailAddress: ['', Validators.compose([Validators.required, CustomValidators.email])],
    });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }
  onClickResetPassword() {
    if (this.modelForm.valid) {
      this.authService.forgotPassword(this.modelForm.value.emailAddress).subscribe(res => {
        if (res.response) {
          this.modalRef.hide();
        }
      });
    } else {
      validateAllFormFields(this.modelForm);
    }
  }
  clearEmailField() {
    this.modalRef.hide();
    this.modelForm.reset();
  }
  onClickLogin() {
    if (this.loginForm.valid) {
      const data = {
        username: this.loginForm.value.email,
        password: sha256(this.loginForm.value.password),
        grant_type: 'password'
      };

      this.authService.login(data).subscribe((res: any) => {
        this.dataService.saveLoginData(res.response, this.loginForm.value.email);
        this.dataService.getFromLocalStorage();
        if (this.dataService.userData === EUserType.SUPER_ADMIN) {
          this.router.navigate(['/admin/manage-order']);
        } else if (this.dataService.userData === EUserType.ADMIN) {
          this.router.navigate(['/admin/manage-order']);
        } else if (this.dataService.userData === EUserType.VENDOR) {
          this.adminService.getVendorById(this.dataService.userId).subscribe((vendorData) => {
            this.dataService.setVendorName(vendorData.response.companyName);
          });
          this.router.navigate(['/vendor/all-products']);
        } else if (this.dataService.userData === EUserType.RETAILER) {
          this.adminService.getVendorById(this.dataService.userId).subscribe((vendorData) => {
            this.dataService.setVendorName(vendorData.response.companyName);
          });
          this.router.navigate(['/retail/all-products']);
        } else if (this.dataService.userData === EUserType.WHOLESALER) {
          this.adminService.getVendorById(this.dataService.userId).subscribe((vendorData) => {
            this.dataService.setVendorName(vendorData.response.companyName);
          });
          this.router.navigate(['/vendor/all-products']);
        } else if (this.dataService.userData === EUserType.KALPAV_WHOLESALE) {
          this.adminService.getVendorById(this.dataService.userId).subscribe((vendorData) => {
            this.dataService.setVendorName(vendorData.response.companyName);
          });
          this.router.navigate(['/kalpav-wholesale/all-products']);
        }
      });
    } else {
      validateAllFormFields(this.loginForm);
    }
  }
}
