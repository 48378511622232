import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NoContentComponent } from './nocontent/nocontent.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { AuthGuardService } from './services/auth.guard.service';
import { AskTheExpertComponent } from './ask-the-expert/ask-the-expert.component';
import { AskTheExpertDetailsComponent } from './ask-the-expert-details/ask-the-expert-details.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module')
      .then((m) => m.RegisterModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module')
      .then((m) => m.AdminModule), canActivate: [AuthGuardService]
  },
  { path: 'vendor', loadChildren: () => import('./vendor/vendor.module').then(m => m.VendorModule), canActivate: [AuthGuardService] },
  { path: 'retail', loadChildren: () => import('./retail/retail.module').then(m => m.RetailModule), canActivate: [AuthGuardService] },
  { path: 'kalpav-wholesale', loadChildren: () => import('./retail/retail.module').then(m => m.RetailModule), canActivate: [AuthGuardService] },
  { path: 'set-password', component: SetPasswordComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: 'ask-the-expert', component: AskTheExpertComponent },
  { path: 'ask-the-expert-detail', component: AskTheExpertDetailsComponent },
  { path: 'manage-blog', loadChildren: () => import('./manage-blog/manage-blog.module').then(m => m.ManageBlogModule), canActivate: [AuthGuardService] },

  { path: '**', component: NoContentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
