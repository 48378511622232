
import { RestService } from './../core/rest.service';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(public restService: RestService) { }
  getUserById(id): Observable<any> {
    return this.restService.get(Environment.UrlPath + '/users/' + id, null).pipe(
      map(res => res),
      catchError(error => throwError(error))
    );
  }
  changeVendorPasswordForAdmin(newPassword, confirmPassword, vendorId): Observable<any> {
    return this.restService.
      put(Environment.UrlPath + `/user/${vendorId}/password`, {newPassword, confirmPassword}, null).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  registerUser(data, token = '') {
    return this.restService.post(Environment.UrlPath + `/user/register`, data, false, false, false).pipe(map((res: any) => res));
  }

  verifyRegistrationOtp(data) {
    return this.restService.post(Environment.UrlPath + `/user/activate`, data, false, false, false).pipe(map((res: any) => res));
  }

  resendRegistrationOtp(userId) {
    return this.restService.get(Environment.UrlPath + `/user/otp/resend/${userId}`, false, false, false).pipe(map((res: any) => res));
  }

  onSetPassword(password,confirmPassword,token): Observable<any> {
    return this.restService.
      put(Environment.UrlPath + '/user/setPassword', { password, confirmPassword, token}, null).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

}
