<div class="orangeBG minHeight100vh errorBGStyle position-fixed">
    <div class="container">
        <div class="pad17-0">
            <div class="row" *ngIf="error === 'linkExpired'">
                <div class="col-6">
                    <div class="padErrorText text-white text-right">
                        <h1 class="bold font-30 pt-4">Oops, this link is expired</h1>
                        <p class="medium font-16">This URL is not valid anymore.</p>
                        <div class="py-4">
                            <a href="https://app.kalpav.com/">
                                <button class="border-0 font-14 goBackBtn text-white light">Go to home page <img
                                        alt="Go to home page" src="../../assets/images/arrow-right.png"
                                        class="arrowImg"></button>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-6"><img alt="Link Expired" src="../../assets/images/link-expired.png"
                        class="padErrorImg" />
                </div>
            </div>
        </div>
    </div>
</div>