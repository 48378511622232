
import { Directive, HostListener } from '@angular/core';
@Directive({
    selector: '[noMultipleSpace]',
    host: {
        '(input)': '$event'
    }
})
// This directive will not allow enter continuous space in between words like "This  is".
export class NoMultipleSpaceDirective {
    constructor() { }

    @HostListener('input', ['$event']) onInput($event) {
        let originalString = $event.target.value as string;
        const originalStringArray = originalString.split('');
        if (originalStringArray.length > 1 && originalStringArray[originalStringArray.length - 2] === ' ' && originalStringArray[originalStringArray.length - 1] === ' ' ) {
            originalString = originalString.substring(0, originalString.length - 1)
        }
        $event.target.value = originalString;
    }
}