<div class="container">
  <div class="row">
    <div class="col-8">
      <h1 class="bold font-24 py-4">Ask the Expert</h1>
    </div>
  </div>
  <div class="pb-5">
    <div class="px-3 tableShadow bg-white">
      <div class="row font-12 light text-dark tableHead py-2">
        <div class="col maxWidth-70">
          <div class="py-1">Sl No</div>
        </div>
        <div class="col">
          <div class="py-1 text-left">Date and Time</div>
        </div>
        <!-- <div class="col">
                    <div class="py-1 text-left">Time</div>
                </div> -->
        <div class="col">
          <div class="py-1 text-center">Name</div>
        </div>
        <div class="col">
          <div class="py-1 text-center">Email</div>
        </div>
        <div class="col">
          <div class="py-1 text-center">Phone No.</div>
        </div>
        <div class="col">
          <div class="py-1 text-center">Category</div>
        </div>
        <div class="col maxWidth-70"></div>
      </div>

      <div
        class="row font-12 regular tableDiv py-2"
        *ngFor="let detail of allExpertDetails; let i = index"
      >
        <div class="col text-truncate maxWidth-70">
          <div class="py-2 text-truncate">{{ i + 1 + initIndex }}</div>
        </div>
        <div class="col text-truncate">
          <div class="py-2 text-truncate text-left">
            {{ detail.bookingDate | dateAndTime }}
          </div>
        </div>
        <!-- <div class="col text-truncate">
            <div class="py-2 text-truncate text-left">
            </div>
          </div> -->
        <div class="col text-truncate">
          <div class="py-2 text-truncate text-center">
            {{ detail.senderName }}
          </div>
        </div>
        <div class="col text-truncate">
          <div class="py-2 text-truncate text-center">
            {{ detail.senderEmail }}
          </div>
        </div>
        <div class="col text-truncate">
            <div class="py-2 text-truncate text-center">
              {{ detail.senderPhoneNumber }}
            </div>
          </div>
        <div class="col text-truncate">
          <div class="py-2 text-truncate text-center">
            {{ detail.category }}
          </div>
        </div>
        <div class="col maxWidth-70">
            <div class="py-2 text-center">
                <div class="btn-group" dropdown [placement]="'left'">
                    <button id="button-basic" dropdownToggle type="button" class="border-0 bg-transparent" aria-controls="dropdown-basic">
                        <img alt="Options" src="../../../assets/images/option.svg"
                            class="activateDeactivateIcon">
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem"><a class="dropdown-item font-14 regular pointer" (click)="onDetails(detail.askTheExpertId)" >View Details</a></li>
                        
                    </ul>
                </div>
            </div>
        </div>
      </div>
      
    </div>
    <div class="text-center py-4 w-100">
      <pagination [totalItems]="totalElements" previousText="&laquo;" nextText="&raquo;" [itemsPerPage]="pageSize" [(ngModel)]="currentPage" [maxSize]="3" (pageChanged)="pageChanged($event)"></pagination>
    </div>
  </div>
</div>
