import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTextUpperCase]',
  providers: [],
})
export class TextUpperCaseDirective {
  enableFlag = true;
  constructor(
    private control: NgControl
  ) {
  }
  @HostListener('input', ['$event']) onEvent($event) {
    if ($event.data === ' ') {
      this.enableFlag = true;
    }
    if ($event.inputType === 'deleteContentBackward' ||
        $event.currentTarget.selectionStart !== $event.currentTarget.value.length) { // resolves issue when typing in middle
      this.enableFlag = false;
    }
    const upper = this.titleCaseTransform(this.control.value);
    this.control.valueAccessor.writeValue(upper);
  }
  titleCaseTransform(str) {
    const splitStr = str.split(' ');
    const lastIndex = splitStr.length - 1;
    if (this.enableFlag) {
      splitStr[lastIndex] = splitStr[lastIndex].charAt(0).toUpperCase() + splitStr[lastIndex].substring(1);
      return splitStr.join(' ');
    } else {
      return str;
    }
  }
}
